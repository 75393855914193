import { cva } from 'class-variance-authority';

export const cvaRoot = cva(
  [
    'StoresMapStyles-cvaRoot',
    'fixed left-0 top-0 z-[200]',
    'w-full h-screen',
    'bg-cWhite',
  ],
  {
    variants: {
      invisible: {
        true: ['!opacity-0'],
        false: ['opacity-100'],
      },
    },
  }
);

export const cvaMapWrapper = cva([
  'StoresMapStyles-cvaMapWrapper',
  'absolute w-full md:w-[calc(100%-41rem)] lg:w-[calc(100%-52rem)] h-full left-0 top-0',
]);

export const cvaMap = cva([
  'StoresMap-cvaMap',
  'absolute w-full h-full left-0 top-0',
]);

export const cvaAside = cva(
  [
    'StoresMap-cvaAside',
    'flex-col',
    'fixed right-0 top-7.2 md:top-0 bottom-0 md:bottom-auto z-[10] overflow-hidden',
    'w-full md:w-[44rem] lg:w-[56rem] md:h-screen hidden md:flex',
    'bg-cWhite',
    'rounded-[3.2rem] rounded-tr-none rounded-br-none',
  ],
  {
    variants: {
      paddingBottom: {
        true: ['pb-8'],
        false: [''],
      },
      showDetails: {
        true: [''],
        false: ['hidden md:flex'],
      },
    },
    defaultVariants: {
      paddingBottom: false,
      showDetails: false,
    },
  }
);

export const cvaAsideHeader = cva([
  'StoresMapStyles-cvaAsideHeader',
  'px-3.2 lg:px-8 pt-1.2 md:pt-4.8 pb-3.2',
  'w-full',
  'bg-cLightGrey',
]);

export const cvaAsideHeaderTitle = cva([
  'StoresMapStyles-cvaAsideHeaderTitle',
  'mb-2.4',
  'text-h3 font-secondary',
]);

export const cvaAsideHeaderActiveCity = cva(
  ['StoresMapStyles-cvaAsideHeaderActiveCity'],
  {
    variants: {
      onMainPage: {
        true: [
          'text-cBlack border-none cursor-default',
        ],
        false: [
          'border-b border-0.1 border-cMediumGreen border-dashed',
          'text-cMediumGreen',
          'cursor-pointer',
        ],
      },
    },
  }
);

export const cvaAsideScroll = cva([
  'StoresMapStyles-cvaAsideScroll',
  'overflow-y-auto',
]);

export const cvaAsideItems = cva([
  'StoresMapStyles-cvaAsideItems',
  'w-full',
]);

export const cvaClose = cva([
  'StoresMapStyles-cvaClose',
  'flex items-center justify-center',
  'w-4 md:w-5.6 h-4 md:h-5.6',
  'bg-cWhite',
  'rounded-[1.2rem] md:rounded-[1.6rem]',
  'cursor-pointer select-none',
  'transition-colors duration-300',
  'shadow-mapClose',
  'group',
]);

export const cvaCloseWrapper = cva(
  [
    'StoresMapStyles-cvaCloseWrapper',
    'absolute top-1.6 md:top-4 z-[10]',
  ],
  {
    variants: {
      left: {
        true: [
          'left-2.4 sm:right-6.4 sm:left-auto',
        ],
        false: ['right-2.4 md:right-6.4'],
      },
    },
  }
);

export const cvaCloseIcon = cva([
  'StoresMapStyles-cvaCloseIcon',
  'w-1.6 h-1.6',
  '[&>path]:stroke-cBlack',
  '[&>path]:ransition-colors [&>path]:duration-300',
]);

export const cvaBack = cva([
  'StoresMapStyles-cvaBack',
  'flex items-center justify-center',
  'w-4 md:w-5.6 h-4 md:h-5.6',
  'bg-cWhite',
  'rounded-[1.2rem] md:rounded-[1.6rem]',
  'cursor-pointer select-none',
  'transition-colors duration-300',
  'shadow-mapClose',
  'group',
]);

export const cvaBackWrapper = cva([
  'StoresMapStyles-cvaBackWrapper',
  'absolute top-1.6 md:top-4 left-2.4 md:left-auto md:right-6.4 z-[10]',
]);

export const cvaBackIcon = cva([
  'StoresMapStyles-cvaBackIcon',
  'w-2.4 h-2.4',
  '-rotate-180',
  '[&>path]:stroke-cBlack',
  '[&>path]:ransition-colors [&>path]:duration-300',
]);

export const cvaZoom = cva([
  'StoresMapStyles-cvaZoom',
  'absolute right-2.4 md:right-6.4 top-1/2 -translate-y-1/2 z-[10]',
  'flex flex-col gap-1.2',
]);

export const cvaZoomButton = cva([
  'StoresMapStyles-cvaZoomButton',
  'flex items-center justify-center',
  'w-4 md:w-5.6 h-4 md:h-5.6',
  'bg-cWhite',
  'rounded-[1.2rem] md:rounded-[1.6rem]',
  'cursor-pointer select-none',
  'transition-colors duration-300',
  'shadow-mapClose',
  'group',
]);

export const cvaZoomButtonIcon = cva([
  'StoresMapStyles-cvaZoomButtonIcon',
  'w-1.6 h-1.6',
  'stroke-cBlack',
  'transition-colors duration-300',
]);

export const cvaStoreDetails = cva([
  'StoresMapStyles-cvaStoreDetails',
  '',
]);

export const cvaAsideButtonWrapper = cva([
  'StoresMapStyles-cvaAsideButtonWrapper',
  'absolute left-0 bottom-3',
  'w-full',
  'bg-cWhite px-2.4 sm:px-3.2 lg:px-8 md:py-2.4 pb-5 pt-2.4',
]);

export const cvaAsideButton = cva([
  'StoresMapStyles-cvaAsideButton',
  'block w-full',
  'text-bodyM sm:text-bodyMSemibold',
]);

export const cvaSearch = cva([
  'StoresMapStyles-cvaSearch',
  'relative',
]);

export const cvaSearchInput = cva([
  'StoresMapStyles-cvaSearchInput',
  'block',
  'w-full h-5.6',
  'py-1.6 px-2.4',
  'bg-cWhite',
  'rounded-[1.6rem]',
  'border-none outline-none',
  'text-bodyM placeholder:text-cBlack placeholder:text-opacity-[0.48]',
  'focus:placeholder:opacity-0',
]);

export const cvaSearchIcon = cva([
  'StoresMapStyles-cvaSearchIcon',
  'absolute z-[1] right-1.6 lg:right-2.4 top-1/2 -translate-y-1/2',
  'w-2.4 h-2.4',
  'pointer-events-none',
]);

export const cvaSheet = cva([
  'StoresMapStyles-cvaSheet',
  'relative z-[1200]',
  'md:hidden',
  'bg-cWhite',
]);

export const cvaBottomSheetHeader = cva(
  [
    'StoresMapStyles-cvaBottomSheetHeader',
    'py-1',
  ],

  {
    variants: {
      bottom: {
        true: [
          'StoresMapStyles-cvaBottomSheetHeader--bottom',
        ],
        false: [''],
      },
    },
    defaultVariants: {
      bottom: false,
    },
  }
);
